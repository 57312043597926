import './App.scss';
import { Home } from './pages/homepage/Home';
import { Route , Routes } from 'react-router-dom';
import { AboutUs } from './pages/about/AboutUs';
import { BrowserRouter as Router } from 'react-router-dom';
import { Services } from './pages/services/Services';
import { Products } from './pages/products/Products';
import { ContactUs } from './pages/contactUs/ContactUs';
import { FrontEndPoints } from './common/common';
import { Careers } from './pages/career/Careers';
import { Clients } from './pages/clients/Clients';

function App() {
  return (
    <div className="App">
     <Router>
      <Routes>
          <Route index element={<Home />}/>
          <Route path={FrontEndPoints.AboutUs} element={<AboutUs />}/>
          <Route path={FrontEndPoints.Services} element={<Services />}/>
          <Route path={FrontEndPoints.Contact} element={<ContactUs />} />
          <Route path={FrontEndPoints.Products} element={<Products />} />
          <Route path={FrontEndPoints.Career} element={<Careers />} />
          <Route path={FrontEndPoints.Clients} element={<Clients />} />
        </Routes>
     </Router>
    </div>
  );
}

export default App;
