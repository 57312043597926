import React from 'react';
import './Teams.scss';
import TeamCard from '../teamCard/TeamCard';

const Teams = ({ items }:any) => {
  return (
    <section className='teams-section'>
        <div className="section-info">
            <img src='./images/teams-icon.png' alt='icon' />
            <h2>Our Team</h2>
            <p>Meet our team of experts who are passionate about technology and committed to delivering exceptional results. Our team members are dedicated professionals with years of experience in their respective fields.</p>
        </div>
        <div className="teams-list">
            {items.map((item:any, index:any) => (
                <TeamCard item={item} key={index} />
            ))}
        </div>
    </section>
  );
};

export default Teams;
