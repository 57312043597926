import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import './Carousel.scss';

import { Pagination,Navigation} from 'swiper/modules';

interface CarouselProps {
    data: any[]; // Define a more specific type if you know the data structure
    isPagination?:boolean
  }
  
  const Carousel: React.FC<CarouselProps> = ({ data,isPagination=true }) => {
    return (
      <Swiper
        // spaceBetween={15}
        slidesPerView={7}
        navigation
        pagination={isPagination} modules={[Pagination,Navigation]}
        autoplay={{ delay: 3000 }}
        loop={true}
        breakpoints={{
            0:{
              slidesPerView:1,
            },
            // when window width is >= 640px
            640: {
              slidesPerView: 1,
            },
            // when window width is >= 768px
            768: {
              slidesPerView: 2,
            },
            // when window width is >= 1024px
            1024: {
              slidesPerView: 4,
            },
            // when window width is >= 1440px
            1440: {
              slidesPerView: 6,
            },
          }}
          className='custom-swiper'
      >
        {data.map((d, index) => (
          <SwiperSlide key={index}>
            {d}
          </SwiperSlide>
        ))}
      </Swiper>
    );
  };
  
  export default Carousel;
  