import { Button } from '../button/Button';
import SmallCard from './smallCard/SmallCard';
import './WhyUS.scss';

export const WhyUS = ({title,description,image, ...props}:any) => {
    return (
        <section className="why-us-section">
          <div className='why-us-main'>
            <div className="why-us-left">
              <h2>WHY US</h2>
              <div className='why-us-text'>
                <p>
                Ciphermind offers innovative technology solutions with a client-centric approach. We deliver cost-effective, high-quality services, ensuring your business success. Trusted by 40+ clients globally, we prioritize your vision and provide expert support.
                </p>
                <Button value={"Schedule A Meeting"} isRounded={true} />
              </div>
            </div>

            
      
            <div className="why-us-right">
              <div className="card-grid">
                <div className="card">
                  <h4>Next-Gen Technology Provider</h4>
                  <p>
                    Delivering innovative solutions for modern challenges.
                  </p>
                </div>
                <div className="card">
                  <h4>Assured Services</h4>
                  <p>We consistently lead in client satisfaction, delivering exceptional service every time.</p>
                </div>
                <div className="card">
                  <h4>Worldwide Clients</h4>
                  <p>Over 40+ clients worldwide trust us for reliable and effective solutions.</p>
                </div>
                <div className="card">
                  <h4>It's All About Technology</h4>
                  <p>Round-the-clock support from our experts to ensure your business success.</p>
                </div>
                <div className="card">
                  <h4>Client-Centric Focus</h4>
                  <p>Your vision is our priority; we tailor solutions to your unique requirements.</p>
                </div>
                <div className="card">
                  <h4>Cost Effectiveness</h4>
                  <p>We excel in cost-effective solutions, ensuring high quality while reducing your expenses.</p>
                </div>
              </div>
            </div>
            </div>
        </section>
      );
}