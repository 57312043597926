
import { Header } from "../../components/header/Header";
import './AboutUs.scss';
import { MeetingInfoSection } from "../../components/meetingInfoSection/MeetingInfoSection";
import { Footer } from "../../components/footer/Footer";
import { AboutUsCard } from "../../components/AboutUsCard/AboutUsCard";
import { AboutOverview } from "../../components/aboutOverview/AboutOverview";
import DynamicList from "../../components/dynamicListView/DynamicList";
import Teams from "../../components/teams/Teams";
import { WhyUS } from "../../components/whyUs/WhyUS";


export const AboutUs =() =>{

    const items = [
        {
          id: 1,
          image: './images/development.png',
          title: 'Product development',
          description: 'Have an idea and looking for the right team? Talk to us. We help businesses grow by converting their ideas to life.',
        },
        {
          id: 2,
          image: './images/business.png',
          title: 'Business Solutions',
          description: 'Comprehensive business services and solutions that drive success.',
        },
        {
          id: 3,
          image: './images/manage.png',
          title: 'Managed IT Services',
          description: 'Efficient and reliable IT services for seamless business operations.',
        },
        {
          id: 3,
          image: './images/future.png',
          title: 'Future-Ready Approach',
          description: 'Prepare for emerging technologies to keep your business ahead.',
        },
    ];
    const teamsItem = [
        {
          id: 1,
          image: './team/1.jpg',
          icon: './images/icon-linkedin.png',
          name: 'Liam Walker',
          description: 'Finance Manager',
        },
        {
          id: 1,
          image: './team/2.jpg',
          icon: './images/icon-linkedin.png',
          name: 'Megan Clark',
          description: 'Project Manager',
        },
        {
          id: 1,
          image: './team/3.jpg',
          icon: './images/icon-linkedin.png',
          name: 'Michael Lee',
          description: 'Blockchain Developer',
        },
        {
          id: 1,
          image: './team/4.jpg',
          icon: './images/icon-linkedin.png',
          name: 'Emily Brown',
          description: 'AI/ML Engineer',
        },
        {
          id: 1,
          image: './team/5.jpg',
          icon: './images/icon-linkedin.png',
          name: 'David Williams',
          description: 'UI/UX Designer',
        },
        {
          id: 1,
          image: './team/6.jpg',
          icon: './images/icon-linkedin.png',
          name: 'Daniel Wilson',
          description: 'DevOps Engineer',
        },
        {
          id: 1,
          image: './team/7.jpg',
          icon: './images/icon-linkedin.png',
          name: 'Grace Taylor',
          description: 'Cybersecurity Expert',
        },
        {
          id: 1,
          image: './team/8.jpg',
          icon: './images/icon-linkedin.png',
          name: 'Charlotte Lewis',
          description: 'HR Manager',
        }
    ];
      
    
    return(
        <div className="about" >
            <Header /> 
            <div className="heading-text">About Us</div>
            <div className="aboutus-text">
                <div className="about-description">CipherMind, your gateway to a world of innovative excellence in AI, Blockchain, & beyond. From user-friendly iOS & Android apps developed by our Mobile App Development team to the intricacies of Microsoft D365 technical functional support, Website Development, and Networking services. . We also offer support for Microsoft D365, build websites, and provide networking services. Let us help you reach new heights in your business journey
                </div>
            </div>
            <div className="main-img">
                <img className="main-about-image" src="./images/aboutus-image.svg" />
            </div>
            <div className="about-cards">
                <AboutUsCard icon='./images/target.png' title='OUR MISSION' description='Our mission is to empower businesses with cutting-edge technology solutions that enhance productivity, efficiency, and growth.' />
                <AboutUsCard icon='./images/start.png' title='OUR Vision' description='We envision a future where businesses of all sizes can leverage the power of technology to achieve their goals and create lasting value.' />
            </div>
            <AboutOverview />
            <div className="about-section">
                <DynamicList items={items} />
            </div>
            <div className="team">
                <Teams items={teamsItem} />
            </div>
            <WhyUS />
             <MeetingInfoSection />
            <Footer />
        </div>
    );
}