import React from 'react';
import styles from './Tabs.module.scss';

interface TabsProps {
  activeTab: string;
  onTabClick: (tab: string) => void;
}

const Tabs: React.FC<TabsProps> = ({ activeTab, onTabClick }) => {
  const categories = ['All Recent', 'Finance', 'Development', 'Marketing', 'Specialist'];

  return (
    <div className={styles.tabs}>
      {categories.map((category) => (
        <button
          key={category}
          className={`${styles.tab} ${activeTab === category ? styles.active : ''}`}
          onClick={() => onTabClick(category)}
        >
          {category}
        </button>
      ))}
    </div>
  );
};

export default Tabs;
