import './AboutOverview.scss';
import { CountComponent } from './CountComponent';


export const AboutOverview =({title,description,icon}:any) =>{

    
    
    return(
        <>
            <section className='overview-section'>
                <div className='overview-text'>
                    <h2 className='overview-heading'>Ciphermind helps start-ups, SMEs & enterprises grow their business</h2>
                    <p className='overview-description'>We’ve been helping customers since 2009 and take pride in delivering high-quality custom services designed to help you build, grow, and revolutionize your business.</p>
                </div>
                <div className="overview-card">
                    <div className='overview-image'>
                        <img src="./images/overview-image.png"  alt='image' />
                    </div>
                    
                    <div className="overview-card-info">
                        <CountComponent title='500+' description='Active Clients' />
                        <div className='baseLine'></div>
                        <CountComponent title='200+' description='Projects Delivered' />
                        <div className='baseLine'></div>
                        <CountComponent title='50+' description='Countries Supported' />
                    </div>
                </div>

            </section>
        </>
    );
}