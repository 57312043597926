
import Carousel from '../../common/carousel/Carousel';
import './ClutchSection.scss';

export const ClutchSection = ({title,description,image, ...props}:any) => {

    var clutchList = [
        {
            name:"CipherMind",
            link:'./ai-clutch-2.png'
        },
        {
            name:"CipherMind",
            link:'./ai-clutch-2.png'
        },
        {
            name:"CipherMind",
            link:'./ai-clutch-2.png'
        },
        {
            name:"CipherMind",
            link:'./ai-clutch-2.png'
        },
        {
            name:"CipherMind",
            link:'./ai-clutch-2.png'
        },
        {
            name:"CipherMind",
            link:'./ai-clutch-2.png'
        },
        {
            name:"CipherMind",
            link:'./ai-clutch-2.png'
        },
        {
            name:"CipherMind",
            link:'./ai-clutch-2.png'
        },
        
        {
            name:"CipherMind",
            link:'./ai-clutch-2.png'
        },
        
    ];
    var List = [
        <div className="clutch-view"><img src={'./ai-clutch-2.png'} className="clutch-image" /></div>,
        <div className="clutch-view"><img src={'./ai-clutch-2.png'} className="clutch-image" /></div>,
        <div className="clutch-view"><img src={'./ai-clutch-2.png'} className="clutch-image" /></div>,
        <div className="clutch-view"><img src={'./ai-clutch-2.png'} className="clutch-image" /></div>,
        <div className="clutch-view"><img src={'./ai-clutch-2.png'} className="clutch-image" /></div>,
        <div className="clutch-view"><img src={'./ai-clutch-2.png'} className="clutch-image" /></div>,
        <div className="clutch-view"><img src={'./ai-clutch-2.png'} className="clutch-image" /></div>,
        <div className="clutch-view"><img src={'./ai-clutch-2.png'} className="clutch-image" /></div>,
        <div className="clutch-view"><img src={'./ai-clutch-2.png'} className="clutch-image" /></div>,
        <div className="clutch-view"><img src={'./ai-clutch-2.png'} className="clutch-image" /></div>,
        <div className="clutch-view"><img src={'./ai-clutch-2.png'} className="clutch-image" /></div>,
        <div className="clutch-view"><img src={'./ai-clutch-2.png'} className="clutch-image" /></div>,
        <div className="clutch-view"><img src={'./ai-clutch-2.png'} className="clutch-image"  /></div>
    ];
    return (
        <section className="clutch-section">
                <Carousel data={List}/>
        </section>
    );
}