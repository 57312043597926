import React from 'react';
import './JobCard.scss';
import { Button } from '../button/Button';

interface JobCardProps {
  title: string;
  company: string;
  type: string;
  mode: string;
  salary: string;
  applicants: number;
}

const JobCard: React.FC<JobCardProps> = ({ title, company, type, mode, salary, applicants }) => {
  return (
    <div className={'job-card'}>
        <div className={'overlay'}></div>
        <img src="./career/card-bg.svg" alt="Background" className={'background'} />
        <div className={'jobDetail'}>
            <div className={'tags'}>
                <Button value={type} isGradientBordered/>
                <Button value={mode} isGradientBordered />
                <Button value={salary} isGradientBordered />
            </div>
            <div className='job-info'>
                <h3 className='title'>{title}</h3>
                <p className='description'>{company}</p>
            </div>
            <div className={'applicants-action'}>
                <Button value={'Apply'} isRounded />
                <div className='applicants'>
                    <span><img src='./career/people.svg' alt='icon' /></span> {applicants} Applied
                </div>
            </div>
        </div>
    </div>
  );
};

export default JobCard;
