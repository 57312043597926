import { useState } from 'react';
import { Button } from '../button/Button';
import './Header.scss';
import { Link, useNavigate } from 'react-router-dom';
import { FrontEndPoints } from '../../common/common';

export const Header=() =>{
    const navigate = useNavigate();

    const [isNavOpen, setIsNavOpen] = useState(false);

    const handleToggle = () => {
        setIsNavOpen(!isNavOpen);
    };

    const handleDropdownToggle = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        const target = e.currentTarget.nextElementSibling as HTMLElement;
        if (target) {
        target.classList.toggle('active');
        }
    };
    return(
        <section className="navigation">
            <div className="nav-container">
                <div className="brand" onClick={()=> navigate(FrontEndPoints.Home)}>
                    <img className="brand_logo" src="./logo.png" alt="Logo" />
                </div>
                <nav>
                    <div className="nav-mobile">
                        <a id="nav-toggle" href="javascript::void" onClick={handleToggle}>
                            <span />
                            <span />
                            <span />
                        </a>
                    </div>
                    <ul className={`nav-list ${isNavOpen ? 'active' : ''}`}>
                        <li className='list'><Link className='list-link' to={FrontEndPoints.AboutUs}>About Us</Link></li>
                        <li className='list'><Link className='list-link' to={FrontEndPoints.Services}>Services</Link></li>
                        <li className='list'><Link className='list-link' to={FrontEndPoints.Products}>Products</Link></li>
                        <li className='list'><Link className='list-link' to={FrontEndPoints.Clients}>Clients</Link></li>
                        <li className='list'><Link className='list-link' to={FrontEndPoints.Career}>Career</Link></li>
                        <li className='list'><Link className='list-link' to={FrontEndPoints.Contact}>Contact US</Link></li>
                        <li className='header-button'><Button isRounded value={"Requet a demo"}/></li>                       
                    </ul>
                </nav>
            </div>
        </section>
    );
}