import React from "react";
import "./CardStack.scss";

const CardStack = () => {
  return (
    <div className="card-stack">
      <div className="card">
        <img src="./meeting/image1.jfif" alt="Business Discussion" />
      </div>
      <div className="card">
        <img src="./meeting/image2.jfif" alt="Team Collaboration" />
      </div>
    </div>
  );
};

export default CardStack;
