
import Carousel from '../../common/carousel/Carousel';
import { TestimonialCard } from './testimonialCard/TestimonialCard';
import './TestimonialsSection.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

import { Pagination,Navigation} from 'swiper/modules';


export const TestimonialsSection = ({title,description,image, ...props}:any) => {

    var testimonalList = [
        {
            name:"FutureTech Solutions",
            image:'./testimonials/1.jpg',
            description:'The web application delivered by this team was flawless. The performance is outstanding.'
        },
        {
            name:"AI Innovators",
            image:'./testimonials/2.jfif',
            description:'The AI-powered tool transformed our operations and improved overall efficiency greatly.'
        },
        {
            name:"Digital Horizons",
            image:'./testimonials/3.jpg',
            description:'Their blockchain solutions are secure, efficient, and scalable for seamless integration.'
        },
        {
            name:"AppSphere Ltd.",
            image:'./testimonials/right-profile.jpeg',
            description:'The mobile app exceeded expectations with sleek design and smooth functionality.'
        },
        {
            name:"CloudLogic",
            image:'./testimonials/left-image.jpeg',
            description:'The AI model solved complex problems and greatly enhanced operational efficiency.'
        },
        // {
        //     name:"CipherMind",
        //     link:'./ai-clutch-2.png'
        // },
        // {
        //     name:"CipherMind",
        //     link:'./ai-clutch-2.png'
        // },
        // {
        //     name:"CipherMind",
        //     link:'./ai-clutch-2.png'
        // },
       
        
    ];
    return (
        <section className="testimonal-section">
            <div className='testimonal-info'>
                <h2 className="section-title">Testimonials</h2>
                <h6 className="section-description">We are proud to partner with a diverse range of clients from</h6>
                <h6 className="section-description"> various industries. Our commitment to excellence has earned </h6>
                <h6 className="section-description">us the trust and loyalty of businesses worldwide.</h6>
                <div className="testimonal-view">
                    <Swiper
                        spaceBetween={40}
                        // slidesPerView={7}
                        navigation
                        pagination={true} modules={[Pagination,Navigation]}
                        autoplay={{ delay: 3000 }}
                        loop={true}
                        breakpoints={{
                            0:{
                                slidesPerView:1,
                                
                            },
                            // when window width is >= 640px
                            640: {
                            slidesPerView: 1,
                            },
                            // when window width is >= 768px
                            768: {
                            slidesPerView: 1,
                            },
                            // when window width is >= 1024px
                            1024: {
                            slidesPerView: 2,
                            },
                            // when window width is >= 1440px
                            1440: {
                                slidesPerView: 3,
                            },
                            // when window width is >= 1440px
                            1800: {
                                slidesPerView: 4,
                            },
                        }}
                        className='custom-swiper'
                    >
                        {testimonalList.map((d:any,index:any)=>{
                            return(
                                <SwiperSlide key={index}>
                                    <TestimonialCard image={d.image} name={d.name} description={d.description} />
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                </div>
            </div>
        </section>
    );
}