import './ServiceCard.scss';

export const ServiceCard = ({title,description,image, ...props}:any) => {
    return(
        <>
            <div className='main-card'>
                <img className='card-image' src={image} />
                <h2 className='card-title'>{title}</h2>
                <div className='card-description'>
                    {description}
                </div>
            </div>
        </>
    );
}