
import Carousel from '../../common/carousel/Carousel';
import './ClientsSection.scss';

export const ClientsSection = ({title,description,image, ...props}:any) => {

    var clientList = [
        <img src={'./clients/nexcent.svg'} className="client-image" />,
        <img src={'./clients/jcrea.svg'} className="client-image" />,
        <img src={'./clients/mntn.svg'} className="client-image" />,
        <img src={'./clients/hihami.svg'} className="client-image" />,
        <img src={'./clients/givelife.svg'} className="client-image" />,
        <img src={'./clients/justgo.svg'} className="client-image" />
    ];
    return (
        <section className="client-section">
            <div className='client-info'>
                <h2 className="section-title">clients & Partners</h2>
                <div className="client-view">
                    <Carousel isPagination={false} data={clientList}/>
                </div>
            </div>
        </section>
    );
}