import React from 'react';
import './TeamCard.scss';

const TeamCard = ({ item }:any) => {
  return (
    <div className="team-card">
        <div className="profile-image">
            <img src={item.image} alt={item.name} />
        </div>
        <div className="icon-linkedin">
            <img src={item.icon} alt={'icon'} />
        </div>
        <div className="card-info">
            <div className='card-title'>{item.name}</div>
            <div className='card-description'>{item.description}</div>
        </div>
    </div>
  );
};

export default TeamCard;
