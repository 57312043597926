
import { Footer } from '../../components/footer/Footer';
import { Header } from '../../components/header/Header';
import { MeetingInfoSection } from '../../components/meetingInfoSection/MeetingInfoSection';
import './Products.scss';
import { ProductUL } from '../../components/productUL/ProductUL';
import { Button } from '../../components/button/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';


export const Products =() =>{

//   const location = useLocation();
//   const navigate = useNavigate();
//   useEffect(() => {
//     if (location.pathname === '/products/') {
//       navigate('/products');
//     }
//   }, [location, navigate]);

    const items = [
        {
          id: 1,
          image: './products/aitools.png',
          title: 'AI Power Tools',
          description: 'ChatGPT, an AI tool, excels in information retrieval and natural language understanding, automates tasks, supports decision-making with insights, and enhances accessibility while continuously learning to improve user interactions.',
          list: [
            'Automates tasks, enhancing productivity effortlessly.',
            'Swiftly retrieves information, providing quick & accurate responses.',
            'Comprehends natural language, ensuring intuitive and contextually relevant interactions.',
          ],
        },
        {
          id: 2,
          image: './products/erp.png',
          title: 'ERP Systems',
          description: 'ChatGPT, an AI tool, excels in information retrieval and natural language understanding, automates tasks, supports decision-making with insights, and enhances accessibility while continuously learning to improve user interactions.',
          list: [
            'Automates tasks, enhancing productivity effortlessly.',
            'Swiftly retrieves information, providing quick & accurate responses.',
            'Comprehends natural language, ensuring intuitive and contextually relevant interactions.',
          ],
        },
        {
          id: 3,
          image: './products/blockchain.png',
          title: 'Blockchain Solution',
          description: 'ChatGPT, an AI tool, excels in information retrieval and natural language understanding, automates tasks, supports decision-making with insights, and enhances accessibility while continuously learning to improve user interactions.',
          list: [
            'Automates tasks, enhancing productivity effortlessly.',
            'Swiftly retrieves information, providing quick & accurate responses.',
            'Comprehends natural language, ensuring intuitive and contextually relevant interactions.',
          ],
        },
        {
          id: 4,
          image: './products/mobile.png',
          title: 'Mobile Apps',
          description: 'ChatGPT, an AI tool, excels in information retrieval and natural language understanding, automates tasks, supports decision-making with insights, and enhances accessibility while continuously learning to improve user interactions.',
          list: [
            'Automates tasks, enhancing productivity effortlessly.',
            'Swiftly retrieves information, providing quick & accurate responses.',
            'Comprehends natural language, ensuring intuitive and contextually relevant interactions.',
          ],
        },
        {
          id: 5,
          image: './products/web.png',
          title: 'Web Applications',
          description: 'ChatGPT, an AI tool, excels in information retrieval and natural language understanding, automates tasks, supports decision-making with insights, and enhances accessibility while continuously learning to improve user interactions.',
          list: [
            'Automates tasks, enhancing productivity effortlessly.',
            'Swiftly retrieves information, providing quick & accurate responses.',
            'Comprehends natural language, ensuring intuitive and contextually relevant interactions.',
          ],
        },
    ];
    
    
    return(
        <div className="products-main" >
            <Header /> 
            <div className="heading-text">Our Products</div>
            <div className="main-text">
                <div className="description">Explore our range of innovative products designed to address various <br/> business challenges.</div>
            </div>
            <div className='bg-product'>
              <div className="gradient-overlay"></div>
              <img src="./images/productBg.png" alt="Background" className="background-image" />
              {items.length> 0 && items.map((d:any,index:any) => (
                <div className={`product-sec ${index % 2 === 0 ? '' : 'reversed'}`}>
                  <div className='product-info'>
                    <h2 className='p-title'>{d.title}</h2>
                    <div className='p-detailed'>
                      <p className='p-description'>{d.description}</p>
                      <ProductUL items={d.list} />
                    </div>
                    <Button value='See Case Study' isGradientBordered isRounded />
                  </div>
                  <div className='product-image'>
                    <img src={d.image} alt='product-image' />
                  </div>
                </div>  
              ))}
            </div>
            <MeetingInfoSection />
            <Footer />
        </div>
    );
}