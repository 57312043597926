import React, { useState } from 'react';
import { FaStar, FaRegStar } from 'react-icons/fa';

function RatingComponent({data}:any) {
  const [rating, setRating] = useState(5);

  const handleRating = (index: number) => {
    setRating(index + 1);
  };

  return (
    <div className="rating-container">
      <div className="stars">
        {[...Array(5)].map((_, index) => (
          <span
            key={index}
            onClick={() => handleRating(index)}
            style={{
              fontSize: '30px',
              cursor: 'pointer',
              color: index < rating ? '#F9A82E' : '#ccc',
            }}
          >
            <FaStar />
          </span>
        ))}
      </div>
    </div>
  );
}

export default RatingComponent;

