import './Input.scss';

export const Input = ({value,id,label,type='text',isSelect=false,options=[]as any,isBordered=false,isGradientBordered=false, ...props}:any) =>{
    return(
        <div className={`input-main-div`}>
            {label && <label htmlFor={id}>{label}*</label> }
            {isSelect ? (
                <select id={id}>
                    <option>--Select--</option>
                    {options.map((d:any)=>(
                        <option value={d.value}>{d.label}</option>
                    ))}
                </select>
            ):(
                <input 
                    type={type} id={id}
                    className={` input-gradient-bordered
                        ${isBordered ? 'input-bordered':''} 
                        `
                    } 
                    {...props}
                    value={value}
                />
            )}
        </div>
    )
}
export const TextArea = ({value,id,label,type='text', ...props}:any) =>{
    return(
        <div className={`input-main-div`}>
            {label && <label htmlFor={id}>{label}*</label> }
            <textarea 
                type='text' id={id}
                className={`input-gradient-bordered`} 
                {...props}
                value={value}
                rows={5}
            />
        </div>
    )
}
export const CheckBox = ({value,name,label, ...props}:any) =>{
    return(
        <label className='custom-checkbox'>
            <input type="checkbox" name={name} value={value} />
            <span className="checkmark"></span>
            {label}
        </label>  
    );
}