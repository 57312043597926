
import './AboutOverview.scss';


export const CountComponent =({title,description}:any) =>{

    
    
    return(
        <div className="count-main" >
            <h2 className="heading-text">{title}</h2>
            <p className='count-description'>{description}</p>
        </div>
    );
}