import React from 'react';
import './SubscribeTemplate.scss';
import { Button } from '../button/Button';
import { Input } from '../input/Input';


const SubscribeTemplate: React.FC<any> = () => {
  return (
    <div className={'sub-template'}>
        <h2 className='temp-title'>Join The Team</h2>
        <p className='temp-description'>If you think you'd be a great addition to Ciphermind, send us your CV<br/> to contact@ciphermin.tech and we'll take a look at it.</p>
        <h2 className='temp-title'>Always Get The Latest Information</h2>
        <p className='temp-description'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
        <div>
            <div className='temp-mail'> 
                <Input placeholder="Email" />
                <Button value="Subscribe" />
            </div>
        </div>
    </div>
  );
};

export default SubscribeTemplate;
