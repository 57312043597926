
import { ServiceCard } from '../serviceCard/ServiceCard';
import './ServiceSection.scss';

export const ServiceSection = ({title,description,image, ...props}:any) => {

    var servicesList = [
        {
            title:"Ai Development",
            description:'We create AI-driven solutions for smarter, efficient, and innovative business growth.',
            image:'./services-icon/development.png'
        },
        {
            title:"Blockchain Solution",
            description:'We leverage blockchain for data integrity, seamless transactions, and enhanced trust.',
            image:'./services-icon/blockchain.png'
        },
        {
            title:"Web & App Development",
            description:'We build responsive, user-friendly websites and apps for engagement.',
            image:'./services-icon/webapp.png'
        },
        {
            title:"UI/UX Development",
            description:'Creating intuitive, engaging designs that improve user interaction and satisfaction.',
            image:'./services-icon/uiux.png'
        },
        {
            title:"Metaverse Development",
            description:'Developing interactive metaverse environments for enhanced user interaction and growth.',
            image:'./services-icon/meta.png'
        },
        {
            title:"Microsoft Dynamics 365",
            description:'Streamlining operations and improving customer relationships with real-time insights.',
            image:'./services-icon/development.png'
        },
        {
            title:"Cybersecurity Solutions",
            description:'Protecting data, systems, and networks with comprehensive cybersecurity services.',
            image:'./services-icon/cyber.png'
        },
        {
            title:"Digital Transformation",
            description:'Helping businesses adopt technologies and streamline operations for digital growth.',
            image:'./services-icon/digital.png'
        },
        {
            title:"Custom Development",
            description:'Designing tailored software to address unique business challenges and opportunities.',
            image:'./services-icon/custom.png'
        },
        
    ]
    return (
        <div className="services-section">
                <h2 className="section-title">OUR SERVICES</h2>
                <h6 className="section-description">At Ciphermind, we offer a comprehensive range of </h6>
                <h6 className="section-description">services to meet your technological needs.</h6>

            <div className="servie-card-section">
                {servicesList.map((d:any)=>{
                    return(
                        <ServiceCard title={d.title} image={d.image} description={d.description} />
                    );
                })}
                
            </div>
        </div>
    );
}