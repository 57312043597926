
import { useState } from "react";
import { AboutOverview } from "../../components/aboutOverview/AboutOverview";
import { AboutUsCard } from "../../components/AboutUsCard/AboutUsCard";
import { Button } from "../../components/button/Button";
import { DynamicHorizentalCard } from "../../components/dynamicCard/DynamicHorizentalCard";
import DynamicList from "../../components/dynamicListView/DynamicList";
import { Footer } from "../../components/footer/Footer";
import { Header } from "../../components/header/Header";
import { CheckBox, Input, TextArea } from "../../components/input/Input";
import { MeetingInfoSection } from "../../components/meetingInfoSection/MeetingInfoSection";
import Tabs from "../../components/tabs/Tabs";
import './Careers.scss';
import JobCard from "../../components/jobCard/JobCard";
import SubscribeTemplate from "../../components/subscribeTemplate/SubscribeTemplate";


export const Careers =() =>{

    const jobs = [
        {
          title: 'UX Designer',
          company: 'Ciphermind.tech',
          type: 'Full Time',
          mode: 'Onsite',
          salary: 'Rs. 100k',
          applicants: 24,
        },
        {
          title: 'UI Designer',
          company: 'DesignSphere',
          type: 'Part Time',
          mode: 'Remote',
          salary: 'Rs. 60k',
          applicants: 15,
        },
        {
          title: 'UI Designer',
          company: 'DesignSphere',
          type: 'Part Time',
          mode: 'Remote',
          salary: 'Rs. 60k',
          applicants: 15,
        },
        {
          title: 'UI Designer',
          company: 'DesignSphere',
          type: 'Part Time',
          mode: 'Remote',
          salary: 'Rs. 60k',
          applicants: 15,
        },
        {
          title: 'UI Designer',
          company: 'DesignSphere',
          type: 'Part Time',
          mode: 'Remote',
          salary: 'Rs. 60k',
          applicants: 15,
        },
        {
          title: 'UI Designer',
          company: 'DesignSphere',
          type: 'Part Time',
          mode: 'Remote',
          salary: 'Rs. 60k',
          applicants: 15,
        }
      ];
      const [activeTab, setActiveTab] = useState('All Recent');
    
    return(
        <div className="career-main" >
            <Header /> 
            <div className="heading-text">Career</div>
            <div className="main-text">
                <div className="main-description">One Step Closer To</div>
                <div className="description">Your Dream Job</div>
            </div>
            <div className='bg-jobs'>
                <img src="./career/career-bg.svg" alt="Background" className="background-image" />
                <div className="career-form-container">
                    <h2 className="form-title">Newest <b className="job-title">Jobs</b> For You</h2>
                    <div className="form-description">Open Positions</div>
                    <Tabs activeTab={activeTab} onTabClick={setActiveTab} />
                    <div className={'cards'}>
                        {jobs.map((job, index) => (
                            <JobCard key={index} {...job} />
                        ))}
                    </div>
                </div>
            </div>
            <SubscribeTemplate />
            {/* <MeetingInfoSection /> */}
            <Footer />
        </div>
    );
}