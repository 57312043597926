
import { Link } from 'react-router-dom';
import './Footer.scss';
import { FrontEndPoints } from '../../common/common';

export const Footer = ({title,description,image, ...props}:any) => {

    return (
        <section className="footer-section">
            <div className='footer-main'>
                <div className='footer-baseLine'></div>
                <div className='footer-grid-row'>
                    <div className='footer-grid-col'>
                        <div className="brand">
                            <img className="brand_logo" src="./logo.png" />
                        </div>
                        <p className='brand-description'>CipherMind offers cutting-edge solutions in Artificial Intelligence, mobile app development, and web development. We focus on creating innovative, user-friendly, and efficient platforms tailored to your needs. Partner with us to transform your ideas into reality.
                        </p>
                        <div className='footer-terms-btn'>
                            <a href="#!">Terms And Conditions</a>
                            <a href="#!">Privacy Policy</a>
                        </div>
                    </div>
                    
                    <div className='footer-grid-col'>
                        <h2 className="heading">
                        Company
                        </h2>
                        <ul >
                            <li><Link to={FrontEndPoints.Home}>Home</Link></li>
                            <li><Link to={FrontEndPoints.AboutUs}>About Us</Link></li>
                            <li><Link to={FrontEndPoints.Services}>Services</Link></li>
                            <li><Link to={FrontEndPoints.Products}>Products</Link></li>
                            <li><Link to={FrontEndPoints.Contact}>Contact US</Link></li>                            
                        
                        </ul>
                    </div>
                    <div className='footer-grid-col'>
                        <h2 className="heading">
                            Services & Solutions
                        </h2>
                        <ul >
                            <li><a href="#!">AI Development</a></li>
                            <li><a href="#!">Web & App Development</a></li>
                            <li><a href="#!">Metaverse Development</a></li>
                            <li><a href="#!">Cybersecurity Solutions</a></li>                          
                            <li><a href="#!">Custom Development</a></li>                          
                        
                        </ul>
                    </div>
                    <div className='footer-grid-col'>
                        {/* <h2 className="heading">
                        Services & Solutions
                        </h2> */}
                        <p className='brand-description'>Blockchain Technology Solution</p>
                        <p className='brand-description'>UI/UX Development</p>
                        <p className='brand-description'>Microsoft Dynamics 365</p>
                        <p className='brand-description'>Digital Transformation</p>
                        <div>
                            <p className="footer-contact"> <img className="text-lg" src='./icons/whatsapp.png' /> <a href="https://wa.me/971509538784" target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:underline">+971 509 538 784</a></p>
                        </div>
                        <div>
                            <p className="footer-contact"> <img className="text-lg" src='./icons/mail.png' /> <a href="mailto:contact@ciphermind.tech" className="text-blue-400 hover:underline">contact@ciphermind.tech</a></p>
                        </div>
                        <div className='footer-contact'>
                            Follow for more
                            <div className="flex justify-center space-x-4">
                                <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
                                    <img alt="icon" src='./icons/fb.png' /> 
                                </a>
                                <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
                                    <img alt="icon" src='./icons/x.png' /> 
                                </a>
                                <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
                                    <img alt="icon" src='./icons/instagram.png' /> 
                                </a>
                                <a href="https://www.linkedin.com/company/ciphermind/" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
                                    <img alt="icon" src='./icons/linkedin.png' /> 
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr className='footer-row'></hr>
            <div className='footer-copyright'>
                All rights reserved by Ciphermind &copy; {new Date().getFullYear()} 
            </div>
        </section>
    );
}