
import { AboutOverview } from "../../components/aboutOverview/AboutOverview";
import { AboutUsCard } from "../../components/AboutUsCard/AboutUsCard";
import { Button } from "../../components/button/Button";
import { DynamicHorizentalCard } from "../../components/dynamicCard/DynamicHorizentalCard";
import DynamicList from "../../components/dynamicListView/DynamicList";
import { Footer } from "../../components/footer/Footer";
import { Header } from "../../components/header/Header";
import { CheckBox, Input, TextArea } from "../../components/input/Input";
import { MeetingInfoSection } from "../../components/meetingInfoSection/MeetingInfoSection";
import './ContactUs.scss';


export const ContactUs =() =>{

    
    return(
        <div className="contact-main" >
            <Header /> 
            <div className="heading-text">Get in Touch</div>
            <div className="main-text">
                <div className="description">Ready to discuss your project? Contact us today to learn more about<br/> our services and how we can help your business succeed.</div>
            </div>
            <div className='bg-form'>
                <div className="gradient-overlay"></div>
                <img src="./images/contactBg.svg" alt="Background" className="background-image" />
                <div className="contact-form-container">
                    <h2 className="form-title">Contact Form</h2>
                    <form className="contact-form">
                        <div className="form-row">
                            <Input type="text" id="first-name" label="First Name" placeholder="Enter your first name"/>
                            <Input type="text" id="last-name" label="Last Name" placeholder="Enter your last name"/>
                        </div>
                        <div className="form-row">
                            <Input type="email" id="email" label="Work Email Address" placeholder="Enter your work email address"/>
                            <Input type="text" id="company" label="Company Name" placeholder="Enter your company name"/>
                        </div>
                        <div className="form-row">
                            <Input isSelect id="country" label="Country" placeholder="Select Country" options={
                                [
                                    {label:"Pakistan",value:"Pakistan"},
                                    {label:"India",value:"India"}
                                ]
                            } />
                        </div>
                        <div className="form-row">
                            <TextArea id="text-area" label="What are your requirements?" placeholder="Enter your requirements"  />
                        </div>
                        <div className="form-row">
                            <div className="check-services">
                                <label htmlFor="services">Products/Services*</label>
                                <div className="checkbox-container">
                                    <CheckBox name="services" value="AI Solutions" label ="AI Solutions" />
                                    <CheckBox name="services" value="Blockchain Services" label ="Blockchain Services" />
                                    <CheckBox name="services" value="Mobility Solutions" label="Mobility Solutions"  />
                                    <CheckBox name="services" value="Portal Solutions" label="Portal Solutions"  />
                                    <CheckBox name="services" value="ERP" label="ERP"  />
                                    <CheckBox name="services" value="Cloud Services" label="Cloud Services"  />
                                    <CheckBox name="services" value="Other" label ="Other"  />
                                    <CheckBox name="services" value="Professional Services" label ="Professional Services"  />
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <label htmlFor="consent" className="consent-label">
                                <CheckBox name="consent" value="consent" />
                                By ticking this box you are consenting to allow CIPHER MIND to contact you from time to time via email about products, services, and offers that may be of interest to you. More information on our processing can be found on privacy policy.
                            </label>
                        </div>
                        <div className="form-row">
                            <Button isRounded value={"Submit"}/>
                        </div>
                    </form>
                </div>

            </div>
            <MeetingInfoSection />
            <Footer />
        </div>
    );
}