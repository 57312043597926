
import './ProductUL.scss';

export const ProductUL =({items}:any) =>{
   
    
    return(
        <>
           <ul className='main-custom-list'>
                {items.length > 0 && items.map((d:any,index:any)=>(
                    <li>{d}</li>
                ))}
           </ul>
        </>
    );
}