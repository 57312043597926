
import { ServiceList } from '../serviceList/ServiceList';
import './DynamicHorizentalCard.scss';


export const DynamicHorizentalCard =({title,description,image}:any) =>{
   
    description = [
        'Machine Learning',
        'Natural Language Processing',
        'Predictive Analytics'
    ];
    return(
        <>
            <div className='background-div'>
                <div className="gradient-overlay"></div>
                <img src="./images/services.jpg" alt="Background" className="background-image" />
                <div className='d-card-main'>
                    <div className='d-card-left'>
                        <img alt='image' src={`${image}`} />
                    </div>
                    <div className='d-card-right'>
                        <h3 className='card-title'>AI Development</h3>
                        <ServiceList items={description} />
                    </div>
                </div>
            </div>

        </>
    );
}