import { Button } from "../../components/button/Button";
import { Header } from "../../components/header/Header";
import './Home.scss';
import { WhyUS } from "../../components/whyUs/WhyUS";
import { ProductSection } from "../../components/productSection/ProductSection";
import { ServiceSection } from "../../components/serviceSection/ServiceSection";
import { ClutchSection } from "../../components/clutchSection/ClutchSection";
import { ClientsSection } from "../../components/clientsSection/ClientsSection";
import { TestimonialsSection } from "../../components/testimonialsSection/TestimonialsSection";
import { MeetingInfoSection } from "../../components/meetingInfoSection/MeetingInfoSection";
import { Footer } from "../../components/footer/Footer";


export const Home =() =>{

    return(
        <div className="main_page" >
            <Header />
            <div className="main-ai-text">All in one, AI-Driven</div>
            <div className="main-blockchain-text">Blockchain solutions</div>
            <div className="main-blockchain-description">simplify your journey with blockchain. Our AI solutions redefine</div>
            <div className="main-blockchain-description">possibilities, making your business smarter, faster, and more efficient.</div>
            <div className="main-buttons-row">
                <Button value={"Get Started"} isRounded={true} />
                <Button value={"Ecosystems"} isBordered={true} />
            </div>
            <div className="main-buttons-row main-img">
                <img className="main-page-image" src="./main_page.png" />
            </div>
            <ClutchSection />
            <ServiceSection />
            <WhyUS />
            <ProductSection />
            <ClientsSection />
            <TestimonialsSection />
            <MeetingInfoSection />
            <Footer />
        </div>
    );
}