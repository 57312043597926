import React from 'react';
import './DynamicList.scss';

const DynamicList = ({ items }:any) => {
  return (
    <div className="dynamic-list">
      {items.map((item:any, index:any) => (
        <div
          className={`list-item ${index % 2 === 0 ? 'reversed' : 'normal'}`}
          key={item.id}
        >
          <div className="image">
            <img src={item.image} alt={item.title} />
          </div>
          <div className="text">
            <h3>{item.title}</h3>
            <p>{item.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DynamicList;
