import { ProductCard } from '../productCard/ProductCard';
import './ProductSection.scss';

export const ProductSection = ({title,description,image, ...props}:any) => {
    return (
        <div className="product-section">
            <h2 className="section-title">OUR PRODUCTS</h2>
            <h6 className="section-description">Explore our range of innovative products designed</h6>
            <h6 className="section-description">to address various business challenges.</h6>

            <div className="product-card-section">
                {/* {servicesList.map((d:any)=>{
                    return(
                        <ServiceCard title={d.title} image={d.image} description={d.description} />
                    );
                })} */}
                <ProductCard />
            </div>
        </div>
    );
}