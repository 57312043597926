
import { useState } from "react";
import { Footer } from "../../components/footer/Footer";
import { Header } from "../../components/header/Header";
import { MeetingInfoSection } from "../../components/meetingInfoSection/MeetingInfoSection";
import './Clients.scss';
import SubscribeTemplate from "../../components/subscribeTemplate/SubscribeTemplate";
import { TestimonialsSection } from "../../components/testimonialsSection/TestimonialsSection";
import RatingComponent from "../../components/rating/RatingComponent";


export const Clients =() =>{
    
    return(
        <div className="client-main" >
            <Header /> 
            <div className="heading-text">Our Clients</div>
            <div className="main-text">
                <div className="description">We are proud to work with a diverse range of clients from different <br/>industries. Our commitment to quality and innovation has earned us a <br/>reputation for excellence.</div>
            </div>
            <div className='bg-clients '>
                <div className="gradient-overlay"></div>
                <img src="./images/contactBg.svg" alt="Background" className="background-image" />
                <div className="main-client-side-container left-container">
                    <div className="main-client-side-card left-clip">
                        <div className="client-info">
                            <h2 className="client-card-title">Stacey Prosacco</h2>
                            <img src="./testimonials/vector.png" alt="icon" className="vector-image" />
                            <p className="client-card-desc">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis.Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                            <RatingComponent data={5} />
                        </div>
                    </div>
                    <img src="./testimonials/main-image.jpeg" alt="profile" className="profile-image" />
                    <div className="bg-side-overlay left-bg-clip"></div>
                </div>
                <div className="main-client-container">
                    <div className="main-client-card">
                        <div className="client-info">
                            <h2 className="client-card-title">Stacey Prosacco</h2>
                            <img src="./testimonials/vector.png" alt="icon" className="vector-image" />
                            <p className="client-card-desc">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis.Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                            <RatingComponent data={5} />
                        </div>
                    </div>
                    <img src="./testimonials/main-image.jpeg" alt="profile" className="profile-image" />
                    <div className="bg-overlay"></div>
                </div>
                <div className="main-client-side-container right-container">
                    <div className="main-client-side-card right-clip">
                        <div className="client-info">
                            <h2 className="client-card-title">Stacey Prosacco</h2>
                            <img src="./testimonials/vector.png" alt="icon" className="vector-image" />
                            <p className="client-card-desc">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis.Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                            <RatingComponent data={5} />
                        </div>
                    </div>
                    <img src="./testimonials/main-image.jpeg" alt="profile" className="profile-image" />
                    <div className="bg-side-overlay right-bg-clip"></div>
                </div>
            </div>
            <div className="client-video-container">
              {/* <div className="container"> */}
                <div className="testimonial-card">
                  <video src="./testimonials/testimonial-video.mp4" muted loop className="video" />
                  <div className="testimonial-content">
                    <h2>Stacey Prosacco</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    <div className="rating"> 
                      {/* Your rating component here (e.g., stars) */}
                    </div>
                  </div>
                </div>
              {/* </div> */}
            </div>
            <TestimonialsSection/>
            <SubscribeTemplate />
            <MeetingInfoSection />
            <Footer />
        </div>
    );
}