
import { Header } from "../../components/header/Header";
import './AboutUsCard.scss';
import { MeetingInfoSection } from "../../components/meetingInfoSection/MeetingInfoSection";
import { Footer } from "../../components/footer/Footer";


export const AboutUsCard =({title,description,icon}:any) =>{

    
    
    return(
        <>
            <div className='card-main'>
                <div className='card-content'>
                    <img className='card-image' src={`${icon}`} />
                    <h2 className='card-title'>{title}</h2>
                    <div className='card-description'>
                        {description}
                    </div>
                </div>
            </div>

        </>
    );
}