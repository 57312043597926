import { Button } from '../button/Button';
import './ProductCard.scss';

export const ProductCard = ({title,description,image, ...props}:any) => {
    return (
        <div className="product-card-main">
            <div className='product-image'>
                <img src="./products/product1.jfif"   />
            </div>
            
            <div className="product-card-info">
                <h2>REAL-TIME AI MONITORING SYSTEM</h2>
                <text>Our cutting-edge real-time monitoring system leverages AI to deliver unparalleled surveillance and analytical capabilities. Designed for use in both industrial and public sectors, it provides a robust framework for ensuring safety, improving efficiency, and automating critical processes.</text>
                <ul className="custom-list">
                    <li>Accurately identifies and tracks faces in real-time for enhanced security.</li>
                    <li>Sends immediate notifications for detected events.</li>
                    <li>Detects and categorizes objects within the field of view, supporting industrial automation.</li>
                    <li>Seamlessly connects with existing surveillance setups for streamlined operations.</li>
                </ul>
                <Button value={"Explore More"} isGradientBordered={true} isRounded />
            </div>
        </div>
    );
}