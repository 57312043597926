
import { url } from 'inspector';
import './ServiceList.scss';


export const ServiceList =({items}:any) =>{
   
    
    return(
        <>
           <ul className='main-unorder-list'>
                {items.length > 0 && items.map((d:any,index:any)=>(
                    <li>{d}</li>
                ))}
           </ul>

        </>
    );
}