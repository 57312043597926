
import RatingComponent from '../../rating/RatingComponent';
import './TestimonialCard.scss';

export const TestimonialCard = ({name,description,image,rating, ...props}:any) => {

    
    return (
        <section className="testimonal-card">
            <div className='testimonal-card-image'>
                <img src={`${image}`} alt='image'  className='user-image'/>
            </div>
            <div className='testimonal-detail' 
            // style={{backgroundImage:"url('./testimonials/bg.png')",backgroundSize:'cover', backgroundPosition:'center'}}
            >
                <div className='detail-header'>
                    <img src='./testimonials/logo.svg' alt='testimonial-logo' className='header-image'/>
                    <div>
                        <div className='header-text'>What they say</div>
                        <div className='header-text'>about us?</div>
                    </div>
                </div>
                <h2 className='testimonial-name'>{name}</h2>
                <div className='testimonial-data'>
                    <span className='testimonial-quo-text'><text className='description-quotation'><img src='./testimonials/vector-left.png'/></text>&nbsp;{description}&nbsp;<sub className='description-quotation'><img src='./testimonials/vector-right.png' /></sub></span>
                </div>
                <div className='testimonial-review'>
                    <RatingComponent data={rating} />
                </div>
            </div>
        </section>
    );
}