
import { AboutOverview } from "../../components/aboutOverview/AboutOverview";
import { AboutUsCard } from "../../components/AboutUsCard/AboutUsCard";
import { Button } from "../../components/button/Button";
import { DynamicHorizentalCard } from "../../components/dynamicCard/DynamicHorizentalCard";
import DynamicList from "../../components/dynamicListView/DynamicList";
import { Footer } from "../../components/footer/Footer";
import { Header } from "../../components/header/Header";
import { MeetingInfoSection } from "../../components/meetingInfoSection/MeetingInfoSection";
import Teams from "../../components/teams/Teams";
import { WhyUS } from "../../components/whyUs/WhyUS";
import './Services.scss';


export const Services =() =>{

    const items = [
        {
          id: 1,
          image: './images/development.png',
          title: 'Product development',
          description: 'Have an idea and looking for the right team? Talk to us. We help businesses grow by converting their ideas to life.',
        },
        {
          id: 2,
          image: './images/business.png',
          title: 'Business Solutions',
          description: 'Comprehensive business services and solutions that drive success.',
        },
        {
          id: 3,
          image: './images/manage.png',
          title: 'Managed IT Services',
          description: 'Efficient and reliable IT services for seamless business operations.',
        },
        {
          id: 3,
          image: './images/future.png',
          title: 'Future-Ready Approach',
          description: 'Prepare for emerging technologies to keep your business ahead.',
        },
    ];
    const overviewDescription = [
        {
          title: 'Enhanced Creativity',
          description: 'Generative AI can produce unique and innovative content, designs, and solutions that may not be conceived through traditional methods.'
        },
        {
          title: 'Improved Efficiency',
          description: 'Automating content creation and other tasks reduces manual effort, speeds up processes, and allows your team to focus on higher-value activities.'
        },
        {
          title: 'Scalability',
          description: 'Generative AI models can be scaled to handle large volumes of data and generate outputs at scale, ensuring your business can meet growing demands.'
        },
        {
          title: 'Cost Savings',
          description: 'By automating repetitive tasks, Generative AI helps reduce operational costs and increases overall productivity.'
        },
        {
          title: 'Personalization',
          description: 'AI models can generate personalized content and solutions tailored to individual user preferences, enhancing customer engagement and satisfaction.'
        },
        {
          title: 'Data-Driven Insights',
          description: 'Generative AI provides valuable insights by analyzing large datasets and generating predictive models, helping businesses make informed decisions.'
        },
    ];
    const processDescription = [
        {
          count: '01',
          title: 'Discovery Call',
          description: 'We begin by thoroughly understanding your  objectives.'
        },
        {
          count: '02',
          title: 'Proposal',
          description: 'We propose the infrastructure, systems, and processes to achieve those goals.'
        },
        {
          count: '03',
          title: 'Implementation & Execution',
          description: 'We execute the project plan, which can involve building, testing, deploying & upporting the software.'
        },
        {
          count: '04',
          title: 'Evaluation & Maintenance',
          description: 'We assess the results, incorporate feedback and continually improve.'
        }
    ];
      
    
    return(
        <div className="services-main" >
            <Header /> 
            <div className="heading-text">Services</div>
            <div className="main-text">
                <div className="description">At Ciphermind, we offer a comprehensive range of services to meet<br/> your technological needs.</div>
            </div>
            <DynamicHorizentalCard image='./images/service-header.png'  />
            <div className="benefits-overview">
              <h2 className="overview-title">Benefits</h2>
              <div className="overview-grid">
                {overviewDescription.length > 0 && overviewDescription.map((d:any)=>(
                  <div className="overview-card">
                    <h3 className="o-card-title">{d.title}</h3>
                    <div className="o-card-desc">{d.description}</div>
                  </div>
                ))}
              </div>
            </div>
            <div className="our-process">
              <div className="process-info">
                <h2 className="process-title">The Ciphermind process</h2>
                <div className="process-desc">Our process is a systematic journey comprising four stages: Discovery, Proposal, Implementation, and Optimization.</div>
              </div>
              <div className="process-grid">
                {processDescription.length > 0 && processDescription.map((d:any)=>(
                  <div className="process-card">
                    <h2 className="p-card-count">{d.count}</h2>
                    <h3 className="p-card-title">{d.title}</h3>
                    <div className="p-card-desc">{d.description}</div>
                  </div>
                ))}
              </div>
            </div>
            <MeetingInfoSection />
            <Footer />
        </div>
    );
}