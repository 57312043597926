
import { Button } from '../button/Button';
import CardStack from './CardStack ';
import './MeetingInfoSection.scss';

export const MeetingInfoSection = ({title,description,image, ...props}:any) => {

    return (
        <section className="meeting-section">
            <div className='meeting-info'>
                <div className='meeting-images-section'>
                    <CardStack />
                </div>
                <div className='meeting-detail'>
                    <h2 className='detail-heading'>Ready to accelerate your software development?</h2>
                    <p>Our process is a systematic journey comprising four stages: Discovery, Proposal, Implementation, and Optimization.</p>
                    <Button value={"Schedule A Meeting"} isRounded={true} />
                </div>
            </div>
        </section>
    );
}